<!--
 * @Author: mjzhu
 * @Date: 2022-06-09 10:11:22
 * @LastEditTime: 2024-01-17 19:28:38
 * @FilePath: \awx-ui\src\pages\management\index.vue
-->

<template>
  <div class="project-list">
    <a-button
      v-if="user.isSuperuser"
      style="float: right;margin-bottom: 12px"
      type="primary"
      @click="createProject({})"
      ><svg-icon class="btn-before-icon mgr6" icon-class="file-add"></svg-icon>添加配额</a-button
    >
    <div style="clear: both"></div>
    <div class="flex-warp">
      <div v-for="(item,index) in dataSource" :key="index" class="quoto-item">
          <div class="quoto-header" :style="{background: `url(${contactCard}) no-repeat`}">
            <div class="title">
              {{ item.name }}
            </div>
            <div class="description">{{ item.description }}</div>
          </div>
          <div class="quoto-body">
            <div class="body-warp">
              <div class="left">时长（月）</div>
              <div class="right">{{item.month}}</div>
            </div>
            <div class="body-warp">
              <div class="left">主机配额（个）</div>
              <div class="right">{{item.max_hosts}}</div>
            </div>
            <div class="body-warp">
              <div class="left">任务配额（个）</div>
              <div class="right">{{item.max_tasks}}</div>
            </div>
            <div class="body-warp">
              <div class="left">调度配额（个）</div>
              <div class="right">{{item.max_schedules}}</div>
            </div>
          </div>
          <div class="quoto-footer">
            <a-button class="btn-apply" type="primary" @click="openApplyModal(item)">立即申请</a-button>
          </div>
      </div>
    </div>
  </div>
</template>

<script>
import applyQuotaVue from '@/components/quota/index.vue';
import {mapState, mapGetters} from 'vuex'
import Addment from "./commponents/addment.vue";
import Deletemanagement from "./commponents/deletemanagement.vue";
export default {
  name: "QUOTO",
  data() {
    return {
      contactCard: require("@/assets/img/bg-quoto.png"),
      list: [],
      title: "",
      params: {},
      pagination: {
        total: 0,
        pageSize: 1000,
        current: 1,
        showTotal: (total) => `共 ${total} 条`,
      },
      dataSource: [],
      loading: false
    };
  },
  computed: {
    ...mapGetters("account", ["user"]),
  },
  methods: {
    tableChange(pagination) {
      this.pagination.current = pagination.current;
      this.getProjectList();
    },
    getVal(val, filed) {
      this.params[`${filed}`] = val.target.value;
    },
    //   查询
    onSearch() {
      this.pagination.current = 1;
      this.getProjectList();
    },
    // 添加
    createProject(obj) {
      const self = this;
      let width = 600;
      let title = JSON.stringify(obj) === "{}" ? "添加配额" : "编辑配额";
      let content = (
        <Addment detail={obj} callBack={() => self.getProjectList()} />
      );
      this.$confirm({
        width: width,
        title: title,
        content: content,
        closable: true,
        icon: () => {
          return <div />;
        },
      });
    },
    openApplyModal (item) {
      const self = this;
      let width = 846;
      let title = '申请配额'
      let content = (
        <applyQuotaVue quotaType={'order'} detail={item} callBack={() => self.$router.push('/quota-manage/my-quota')} />
      );
      this.$confirm({
        width: width,
        title: title,
        class:'quota-apply-modal',
        content: content,
        closable: true,
        icon: () => {
          return <div />;
        },
      });
    },
    // 申请
    apply(row) {
      this.$axiosJsonPost(
        global.API.getManagementList + `/${row.id}/apply`
      ).then((res) => {
        console.log(res.status, "申请数据");
        this.$store.commit("addApplyforquotaList", res.data);
        if (res.status) {
          this.$message.success("申请配额成功");
        }
      });
    },
    deleteProject(obj) {
      console.log(obj, "删除");
      const self = this;
      let width = 400;
      let content = (
        <Deletemanagement
          sysTypeTxt="项目"
          detail={obj}
          callBack={() => self.getProjectList()}
        />
      );
      this.$confirm({
        width: width,
        title: () => {
          return (
            <div>
              <a-icon
                type="question-circle"
                style="color:#2F7FD1 !important;margin-right:10px"
              />
              提示
            </div>
          );
        },
        content,
        closable: true,
        icon: () => {
          return <div />;
        },
      });
    },
    getProjectList() {
      this.loading = true;
      const params = {
        page_size: this.pagination.pageSize,
        page: this.pagination.current,
        keyword: this.params.name || ""
      };
      this.$axiosGet(global.API.getManagementList, params).then((res) => {
        this.loading = false;
        if ([200, 201, 204].includes(res.status)) {
          this.dataSource = res.data.results;
          this.pagination.total = res.data.total;
        }
      });
    },
  },
  mounted() {
    this.getProjectList();
  },
};
</script>

<style lang="less" scoped>
.project-list {
  height: calc(100vh - 48px);
  padding: 20px 186px;
  .flex-warp {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    .quoto-item {
      height: 440px;
      width: 23.5%;
      background-image: linear-gradient(0deg, #FFFFFF 70%, #F3F6F7 92%);
      box-shadow: 0px 2px 6px 0px rgba(0,0,0,0.15);
      border-radius: 4px;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      margin-bottom: 24px;
      border-width: 1px;
      margin-right: 2.0%;
      &:nth-child(4n) {
        margin-right: 0;
      }
      .quoto-header {
        height: 104px;
        width: 100%;
        background-size: 100% 100%!important;
        padding: 24px 20px 24px;
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        .title {
          font-size: 19px;
          color: #1D202D;
          letter-spacing: 0;
          font-weight: 600;
          overflow:hidden; //超出的文本隐藏
          text-overflow:ellipsis; //用省略号显示
          white-space:nowrap; //不换行
        }
        .description {
          font-size: 14px;
          color: #798CA6;
          letter-spacing: 0;
          font-weight: 400;
          overflow:hidden; //超出的文本隐藏
          text-overflow:ellipsis; //用省略号显示
          white-space:nowrap; //不换行

        }
      }
      .quoto-body {
        flex: 1;
        padding: 12px 20px 0;
        .body-warp {
          display: flex;
          align-items: center;
          border-bottom: 1px solid rgba(223,225,227,1);
          padding: 10px 0 10px 0;
          // justify-content: space-between;
          .left {
            font-size: 14px;
            color: #374567;
            letter-spacing: 0;
            font-weight: 400;
            width: 80%;
          }
          .right {
            font-size: 16px;
            color: #374567;
            letter-spacing: 0;
            font-weight: 500;
          }
        }
      }
      .quoto-footer {
        height: 80px;
        border-top: 1px solid #DFE1E3;
        text-align: center;
        line-height: 80px;
        .btn-apply {
          width: 200px;
          height: 32px;
          background: #3974F4;
          border-radius: 3px;
          font-size: 14px;
          color: #FFFFFF;
          letter-spacing: 0;
          font-weight: 500;
        }
        .btn-flex {
          display: flex;
          justify-content: space-around;
          .btn-edit {
            width: 120px;
          }
          .btn-del {
            width: 120px;
          }
        }
      }
      &:hover {
        background-image: linear-gradient(0deg, #FFFFFF 70%, #F3F6F7 92%);
        border: 1px solid rgba(57,116,244,0.8);
        box-shadow: 0px 2px 10px 0px rgba(57,116,244,0.37);
        border-radius: 4px;
      }
    }
  }
  .table-info {
    margin-top: 10px;
  }
  .btn-opt {
    border-radius: 2px;
    font-size: 12px;
    color: #0264c8;
    letter-spacing: 0;
    font-weight: 400;
  }
}
</style>
